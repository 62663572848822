
document.body.addEventListener('click', (e) => {
    let $trigger = e.target.closest('.js-delegate-link');
    if ($trigger) {
        let $link = $trigger.querySelector('a');
        if ($link) {
            e.stopPropagation();
            if ($link.target) {
                window.open($link.href, $link.target);
            } else {
                window.location = $link.href;
            }
        }
    }
});