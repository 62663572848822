let header = document.querySelector('header');
const act_is_transparency_header = ! document.querySelector('.l-header--no-transparency');

function header_sticky() {
    const $header = document.querySelector('.l-header');
    if($header){
        const scrollLoc = $header.dataset.previousScrollLocation ? parseInt($header.dataset.previousScrollLocation) : actGetScroll();
        const headerLoc = $header.dataset.previousHeaderLocation ? parseInt($header.dataset.previousHeaderLocation) : 0;
        let curLoc = parseInt(actGetScroll());
        let newHeadLoc = scrollLoc-curLoc+headerLoc;
        // Le multiplicateur est la pour que le principe qui cache
        // le header arrête pas exactement au moment ou il est hors de la page.
        let headSize = $header.offsetHeight*1.1;
        if(newHeadLoc < -headSize){
            newHeadLoc = -headSize;
        }else if(newHeadLoc > 0){
            newHeadLoc = 0;
        }
        $header.dataset.previousScrollLocation = actGetScroll();
        $header.dataset.previousHeaderLocation = newHeadLoc;
        $header.setAttribute('style','top:calc('+newHeadLoc+'px)');
        if (act_is_transparency_header) {
            transparency_header();
        }
    }
}

window.addEventListener('scroll', header_sticky);
if (act_is_transparency_header) {
    new ResizeObserver(transparency_header).observe(header);
}
header_sticky();

function actGetScroll(){
    if (window.pageYOffset != undefined) {
        //return [pageXOffset, pageYOffset];
        return pageYOffset;
    } else {
        var sx, sy, d = document,
            r = d.documentElement,
            b = d.body;
        sx = r.scrollLeft || b.scrollLeft || 0;
        sy = r.scrollTop || b.scrollTop || 0;
        //return [sx, sy];
        return sy;
    }
}

function transparency_header () {
    var first_block = document.querySelector('main > div.entry-content > div');

    if (!first_block) {
        return;
    }

    if (first_block.classList.contains('wp-block-cover') ||  first_block.classList.contains('act-gallery-cover') || first_block.classList.contains('act-hero-slider')) {
        let header = document.querySelector('header');
        let header_height = header.clientHeight;
        let position = header.getAttribute('data-previous-scroll-location');
        if (position==0 || !header.hasAttribute('data-previous-scroll-location')) {
            header.classList.add('l-header--header_cover');
            header.onmouseover = function () {
                header.classList.remove('l-header--header_cover')
            }
            header.onmouseout = function () {
                header.classList.add('l-header--header_cover')
            }
        }
        else if(document.documentElement.scrollTop > (header_height + 20)){
            header.classList.remove('l-header--header_cover')
            header.onmouseover = function () {
                header.classList.remove('l-header--header_cover')
            }
            header.onmouseout = function () {
                header.classList.remove('l-header--header_cover')
            }
        }
        header.style.position = 'fixed';
        header.style.marginBlockStart = 'var(--wp-admin--admin-bar--height)';

        let main = document.querySelector('main');
        const paddingTop = window.getComputedStyle(first_block, null).getPropertyValue('padding-top').match(/\d+/)[0];

        first_block.style.paddingTop = parseInt(header_height) + 'px';
        if(first_block.classList.contains('act-gallery-cover')){
            first_block.style.setProperty("--data-padding-top", parseInt(header_height) + 'px');
        }
        main.classList.add('l-header--header_cover');
    }

}

if (act_is_transparency_header) {
    transparency_header();
}
