console.log('ACTIVIS - App.js loadé');

import './_header';
import './_delegate-link';
(function() {
    var $last_child = document.querySelector('.wp-block-post-content > :last-child');

    while ($last_child && $last_child.tagName === 'SCRIPT') {
        $last_child = $last_child.previousSibling;
    }
    $last_child.classList.add('last-child');
})();

/* retirer les id vide pour ne pas avoir d erreur d'accessibilité */
setTimeout(function () {
    const $refs = document.querySelectorAll("*[id='']");

    if ($refs) {
        
        $refs.forEach(function ($ref) {
            $ref.removeAttribute('id');
        });
    }
}, 2000);
